import React, { useEffect } from "react";
import Readbutton from "./common/Readbutton";
import { Colors } from "../assets/colors";
import "./carouselStyles.css"; // Import the CSS file
import "./carouselScript"; // Import the script file
import Img1 from "./img/image1.jpg";
import Img2 from "./img/image2.png";
import Img3 from "./img/image3.png";
import Header from "./common/Header";
import CarouselItem from "./pages/homscreen/CarouselItemBanner.tsx";
import { leftSlide, rightSlide } from "./carouselScript";

const TopNav = ({
  bannerurl,
  title,
  titledesc,
  showreadmore,
  showbanner,
  showlease = false,
  onClickKnowmore = () => {},
  onContactClick = () => {},
}) => {
  const carouselData = [
    {
      imgSrc: Img2,
      title: "Convert Capex to Opex with Connect’s Leasing Solutions",
      description:
        "Utilize the power of Cashflows and NPV to reduce your upfront costs on assets. Utilize your precious capital for Core Business Expenses Vs Asset Purchase",
      showReadMore: true,
      onClickKnowmore: () => console.log("More Info for Slide 1"),
      onContactClick: () => console.log("Contact for Slide 1"),
      bannerImgSrc: "path_to_banner1.jpg",
      showBanner: true,
    },
    {
      imgSrc: Img3,
      title: "PAN India Presence and Industry Leading Team",
      description:
        "Connect’s PAN India servicing and physical presence in top metros enable smooth and seamless tailor-made custom solutions for business for all sectors, industries and sizes.",
      showReadMore: true,
      onClickKnowmore: () => console.log("More Info for Slide 2"),
      onContactClick: () => console.log("Contact for Slide 2"),
      bannerImgSrc: "path_to_banner2.jpg",
      showBanner: true,
    },
  ];

  useEffect(() => {
    window.addEventListener("leftSlide", leftSlide);
    window.addEventListener("rightSlide", rightSlide);
    return () => {
      window.removeEventListener("leftSlide", leftSlide);
      window.removeEventListener("rightSlide", rightSlide);
    };
  }, []);

  return (
    <div className="w-full bg-no-repeat bg-center bg-cover">
      <Header />
      <div className="h-40vh lg:h-60vh">
        <div className="carousel carousel__fade">
          <div className="carousel_inner">
            <div className="carousel_item carousel_item__active">
              {/* Replace Img1 with your default image source */}
              <img
                src={Img1}
                alt=""
                className="carousel_img h-full w-full lg:w-auto lg:h-auto"
              />
              <div className="carousel_caption">
                <div className="w-full flex justify-center items-center">
                  <div className="flex flex-col md:flex-row w-[80%]">
                    <div className="flex-1">
                      <h1
                        style={{
                          offsetPosition: "normal",
                          color: Colors.white,
                          fontSize: "2rem",
                        }}
                        className="mt-6 md:mt-14 text-3xl sm:text-4xl font-roboto font-light pb-2 border-solid text-left"
                      >
                        {title}
                      </h1>
                      <p
                        style={{
                          offsetPosition: "normal",
                          color: Colors.white,
                          lineHeight: "1.5rem",
                        }}
                        className="text-base sm:text-xl font-roboto font-extralight py-4 text-left md:w-[37vw]"
                      >
                        {titledesc}
                      </p>
                      {showreadmore && (
                        <div className="mt-1 mb-2">
                          <Readbutton
                            onClickKnowmore={onClickKnowmore}
                            onclickContact={onContactClick}
                            buttontext="Read more"
                          />
                        </div>
                      )}
                    </div>
                    {showbanner && (
                      <div className="flex-1 flex justify-end items-center opacity-0">
                        <div className="hidden md:block">
                          <img
                            src={
                              "https://storage.googleapis.com/connectrpl_images/companywebsite/banner.svg"
                            }
                            alt="Banner Logo"
                            className="h-96 mr-9"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Render Carousel Items */}
            {carouselData.map((item, index) => (
              <CarouselItem
                key={index}
                imgSrc={item.imgSrc}
                title={item.title}
                description={item.description}
                showReadMore={item.showReadMore}
                onClickKnowmore={item.onClickKnowmore}
                onContactClick={item.onContactClick}
                bannerImgSrc={item.bannerImgSrc}
                showBanner={item.showBanner}
              />
            ))}
          </div>
          <button
            onClick={() => window.dispatchEvent(new Event("leftSlide"))}
            className="carousel_left-arrow"
          >
            <img
              src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/leftclick.svg"
              alt="Left Arrow"
            />
          </button>
          <button
            onClick={() => window.dispatchEvent(new Event("rightSlide"))}
            className="carousel_right-arrow"
          >
            <img
              src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/rightclick.svg"
              alt="Right Arrow"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
