import React from "react";
import { colorMapping } from "../../../assets/data";
import { Button } from "@nextui-org/react";
import { toast } from "react-toastify";

interface Product {
  ProductID: string;
  Category: string;
  Name: string;
  Model: string;
  Storage: string;
  Varients: string[];
  Specification: string;
  MRP: number;
  MonthlyRent: number;
  BenefitCost: number;
  CompanyCode: string;
  BuybackPrice: number;
  Discount: number;
  IsActive: boolean;
  GST: number;
  ProductImage: string;
  NumberOfInstallments: number;
}

interface CardProps {
  product: Product;
  createOrder: (product: Product) => void;
}

const Card: React.FC<CardProps> = ({ product, createOrder }) => {
  const [activeColor, setActiveColor] = React.useState<string | null>(null);

  // Function to render color variants
  const renderColorVariants = React.useCallback(
    (product: Product) => {
      // Limit the number of colors displayed in a single row
      const maxColorsPerRow = 5;

      const colors = product?.Varients;

      return (
        <div
          className="grid gap-2"
          style={{
            gridTemplateColumns: `repeat(${Math.min(colors?.length, maxColorsPerRow)}, 1fr)`,
          }}
        >
          {colors?.map((color, index) => {
            const hexColor = colorMapping[color];
            const isActive = color === activeColor;
            return hexColor ? (
              <div
                key={index}
                onClick={() => setActiveColor(color)}
                className={`w-4 h-4 rounded-full border ${
                  isActive ? "border-1 border-black" : "border-gray-300"
                }`}
                style={{ backgroundColor: hexColor }}
                title={color}
              ></div>
            ) : null;
          })}
        </div>
      );
    },
    [activeColor], // Add activeColor to dependencies
  );

  const handleCreateOrder = () => {
    if (activeColor) {
      const updatedProduct = { ...product, Varients: [activeColor] };
      createOrder(updatedProduct);
    } else {
      toast.error("Select Color Varient");
    }
  };

  return (
    <div className=" p-3 shadow-md flex rounded-xl">
      <div
        className="flex-shrink-0  bg-white flex justify-center items-center"
        style={{ width: "30%", height: "20vh", overflow: "hidden" }}
      >
        <img
          src={product.ProductImage}
          alt={product.Name}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="flex-grow ml-4 py-2">
        <h2 className="text-lg font-medium font-roboto text-black">
          {product.Model}
        </h2>
        <p className="text-sm text-black font-roboto font-normal">
          {product.Storage}
        </p>
        <div className="flex flex-row  items-center mt-2">
          <p className="text-sm text-black font-roboto font-light mr-2">
            Varients
          </p>
          {renderColorVariants(product)}
        </div>

        <Button
          onClick={handleCreateOrder}
          size="sm"
          className="mt-4 bg-buttonBackground px-10 py-0"
        >
          <p className="text-base text-white font-roboto font-light">Buy</p>
        </Button>
      </div>
      <div className="bg-slate-200 flex flex-col justify-center items-center p-4 rounded-md">
        <p className="text-base text-black font-roboto font-light">
          Get it for
        </p>
        <p className="text-base text-black font-roboto font-normal">
          Rs {product.MonthlyRent * product.NumberOfInstallments}
        </p>
        <p className="text-sm text-black font-roboto font-light">
          MRP {product.MRP}*
        </p>
      </div>
    </div>
  );
};

export default Card;
