import * as React from "react";
import { EmployeeDirectoryType } from "../../../types.ts";
import { Button } from "@nextui-org/react";

interface Props {
  item: EmployeeDirectoryType;
  generateEmployeePassword: (name: string, email: string) => void;
}

export default function Action({ item, generateEmployeePassword }: Props) {
  const handleGeneratePassword = React.useCallback(() => {
    return generateEmployeePassword(item?.name, item?.email);
  }, [generateEmployeePassword, item.email, item.name]);

  return (
    <Button
      color="default"
      variant="solid"
      className="font-roboto"
      size="sm"
      radius="sm"
      onClick={handleGeneratePassword}
    >
      Generate Password
    </Button>
  );
}
