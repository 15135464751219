import React from "react";
import { Route, Routes } from "react-router";

import Dashboard from "./pages/client/Dashboard/Dashboard.tsx";
import RentalSchedule from "./pages/client/RentalSchedule/RentalSchedule.tsx";
import Inventory from "./pages/client/Inventory/Inventory.tsx";
import Invoices from "./pages/client/Invoices/Invoices.tsx";
import Support from "./pages/client/Support/Support.tsx";
import Contact from "./pages/client/Contact/Contact.tsx";
import Documents from "./pages/client/Documents/Documents.tsx";
import ViewInventory from "./pages/client/Inventory/ViewInventory/ViewInventory.tsx";
import NewBusiness from "./pages/client/NewBusiness/NewBusiness.tsx";
import Automation from "./pages/client/Automation/Automation.tsx";
import DashboardEpp from "./pages/epp/Dashboard/Dashboard.tsx";
import EmployeeDirectory from "./pages/client/EmployeeDirectory/EmployeeDirectory.tsx";
import AddEmployees from "./pages/client/EmployeeDirectory/AddEmployees.tsx";
import Products from "./pages/epp/Products/Products.tsx";
import ActiveRentals from "./pages/epp/ActiveRentals/ActiveRentals.tsx";
import ManageOrders from "./pages/epp/ManageOrders/ManageOrders.tsx";
import Orders from "./pages/epp/Employer/Orders/Orders.tsx";
import PDFViewerPage from "./pages/client/Pdfviewer/PDFViewerPage.tsx";
import Client from "./pages/epp/Vendor/Client.tsx";
import ViewOrders from "./pages/epp/Vendor/ViewOrders.tsx";
import OrderDetail from "./pages/epp/Vendor/OrderDetail.tsx";
import UploadProductDetail from "./pages/epp/Vendor/UploadProductDetail.tsx";
import Login from "./pages/client/login/Login.tsx";
import EppLogin from "./pages/epp/login/EppLogin.tsx";

const DashPaths = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/epp/login" element={<EppLogin />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/dashboard/rentalschedule" element={<RentalSchedule />} />
    <Route path="/dashboard/inventory" element={<Inventory />} />
    <Route path="/dashboard/invoices" element={<Invoices />} />
    <Route path="/dashboard/support" element={<Support />} />
    <Route path="/dashboard/contact" element={<Contact />} />
    <Route path="/dashboard/documents" element={<Documents />} />
    <Route path="/dashboard/inventorylist" element={<ViewInventory />} />
    <Route path="/dashboard/newbusiness" element={<NewBusiness />} />
    <Route path="/dashboard/automation" element={<Automation />} />
    <Route path="/dashboard/pdfview" element={<PDFViewerPage />} />

    {/* eppPaths */}

    <Route path="/epphome" element={<DashboardEpp />} />
    <Route
      path="/dashboard/employee-directory"
      element={<EmployeeDirectory />}
    />
    <Route path="/dashboard/add-employees" element={<AddEmployees />} />
    <Route path="/dashboard/orders" element={<Orders />} />

    {/* epp employee paths */}

    <Route path="/dashboard/products" element={<Products />} />
    <Route path="/dashboard/activerentals" element={<ActiveRentals />} />
    <Route path="/dashboard/managerentals" element={<ManageOrders />} />

    {/* epp vendor paths */}
    <Route path="/client" element={<Client />} />
    <Route path="/client/view-orders" element={<ViewOrders />} />
    <Route path="/client/view-order-detail" element={<OrderDetail />} />
    <Route
      path="/client/upload-product-details"
      element={<UploadProductDetail />}
    />
  </Routes>
);

export default DashPaths;
